import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toast";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { customStyles } from "../../../helper/customStyles";
import UploadFileComp from "../../../components/UploadFileComp";
import { IMAGEURL } from "../../../helper/constant";
import { bannerSchema } from "../../../schema";
import MasterServices from "../../../ApiServices/MasterServices";
import uploadImageHandler from "../../../helper/uploadImageHandler"

const AddBanner = () => {
    const { banner_id } = useParams();
    const [singleImageProgress, setSingleImageProgress] = useState(0);
    const [singleImage, setSingleImage] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [selectStatus, setSelectStatus] = useState(null);
    const [editBanner, setEditBanner] = useState([])
    const navigate = useNavigate();

    const formValues = {
        h_banner_type: "",
        h_banner_order: "",
        h_banner_redirect_link: "",
        h_banner_active: "",
        img_alt_tag: "",
        h_banner_image1: "",

    }

    const [initialValues, setInitialValues] = useState(formValues);
    const statusOption = [
        { value: 'Y', label: 'Active' },
        { value: 'N', label: 'Inactive' }
    ];
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
        setFieldTouched
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: bannerSchema,
        onSubmit: async (values) => {

            const body = {
                ...values,
                h_banner_order: values?.h_banner_order ? parseInt(values?.h_banner_order) : "",
                h_banner_active: selectStatus?.value ? selectStatus?.value : null,
                h_banner_image1: singleImage?.includes("https") ? singleImage : IMAGEURL + singleImage
            }

            if (editBanner?.length > 0) {
                editBannerHandler(body)
            } else {
                addBannerHandler(body)
            }
            console.log("body", body)
        }
    })

    const editBannerHandler = async(body) => {
        try {

            
            const payload={
                h_banner_id:banner_id?parseInt(banner_id):null,
                ...body
            }
            console.log("editBannerHandler",payload)
            setSubmitLoader(true)
            const response = await MasterServices.editBanner(payload);
            console.log("body", response)
            if (response?.data?.success === true) {
                setSubmitLoader(false)
                toast.success("banner edited Successfully");
                navigate("/banner")
                clearForm();
            } else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error) {
            setSubmitLoader(false)
            toast.error("An error occurred while editig the banner");
        }
    }
    const addBannerHandler = async(payload) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addBanner(payload);
            console.log("response", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/banner")
            } else {
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
        }
    }

    const getSingleBanner = async (id) => {
        try {
            const response = await MasterServices.getSingleBannerApi(id);
            console.log("getSingleBanner", response)
            if (response?.data?.data?.length > 0) {
                setEditBanner(response?.data?.data)
            } else {
                setEditBanner([])
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const handleFileChange = async (e, setImageState) => {

        const width = 1920;
        const height = 654
       
        uploadImageHandler(e,setImageState,width,height,setFieldValue,setSingleImageProgress)
       
    }

    const clearForm = () => {
        setFieldValue("h_banner_type", "");
        setFieldValue("h_banner_order", "");
        setFieldValue("h_banner_redirect_link", "");
        setFieldValue("img_alt_tag", "");
        setFieldValue("h_banner_active", "");
        setFieldValue("h_banner_image1", "");
        setSelectStatus(null);
        setSingleImage(null);
        navigate("/banner")
    }

    useEffect(() => {
        if (editBanner?.length > 0) {
            console.log("editBanner", editBanner)
            editBanner?.forEach((ele) => {
                setFieldValue("h_banner_type", ele?.h_banner_type);
                setFieldValue("h_banner_order", ele?.h_banner_order);
                setFieldValue("h_banner_redirect_link", ele?.h_banner_redirect_link)
                setFieldValue("img_alt_tag", ele?.img_alt_tag);
                setFieldValue("h_banner_active", ele?.h_banner_active);
                setFieldValue("h_banner_image1", ele?.h_banner_image1);

                if (ele?.h_banner_active) {
                    statusOption?.length > 0 && statusOption.forEach((item) => {
                        if (item?.value === ele?.h_banner_active) {
                            setSelectStatus({
                                label: item?.label,
                                value: item?.value
                            })
                        }
                    })
                }

                setSingleImage(ele?.h_banner_image1)
            })
        }
    }, [editBanner])

    useEffect(() => {
        const fetchData = async () => {
            if (banner_id) {
                await getSingleBanner(banner_id)
            }
        }
        fetchData()
    }, [banner_id])

     


    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">{banner_id ? "Edit Banner" : "Add Banner"}</h1>
            </div>
            <form className="py-5 flex flex-col gap-5 " onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e)
            }}>

                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="p-5 border-b-[1px] border-[#ebac78]">
                        <h1 className="text-md font-semibold text-black">Banner Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-b-lg p-5 py-8 shadow-sm">
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Banner Type
                            </label>
                            <div className="">
                                <input
                                    value={values.h_banner_type}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="h_banner_type"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="eg:large"
                                />
                                {errors.h_banner_type && touched.h_banner_type && (
                                    <div className="text-red-600 text-sm">{errors.h_banner_type}</div>
                                )}

                            </div>
                        </div>


                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Active/Inactive
                            </label>
                            <div>

                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={selectStatus}
                                    options={statusOption}
                                    // onChange={(selectedOption) => {
                                    //     setSelectStatus(selectedOption)
                                    // }}
                                    onChange={(selectedOption) => {
                                        setSelectStatus(selectedOption);
                                        setFieldValue("h_banner_active", selectedOption?.value || ""); // Update formik value
                                        if (!selectedOption) {
                                            setFieldError("h_banner_active", "Status is required");
                                        }
                                    }}
                                    onBlur={() => {
                                        if (!selectStatus) {
                                            setFieldError("h_banner_active", "Status is required");
                                        }
                                    }}
                                    styles={customStyles}
                                    name="h_banner_active"
                                    className="basic-single sm:text-sm"

                                />
                                {errors.h_banner_active && touched.h_banner_active && (
                                    <div className="text-red-600 text-sm">{errors.h_banner_active}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Banner Order
                            </label>
                            <div className="">
                                <input
                                    value={values.h_banner_order}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="h_banner_order"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Order eg:1"
                                />
                                {errors.h_banner_order && touched.h_banner_order && (
                                    <div className="text-red-600 text-sm">{errors.h_banner_order}</div>
                                )}
                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Redirect Link
                            </label>
                            <div className="">
                                <input
                                    value={values.h_banner_redirect_link}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="h_banner_redirect_link"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Link"
                                />
                                {errors.h_banner_redirect_link && touched.h_banner_redirect_link && (
                                    <div className="text-red-600 text-sm">{errors.h_banner_redirect_link}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Image Tag
                            </label>
                            <div className="">
                                <input
                                    value={values.img_alt_tag}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="img_alt_tag"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Tag"
                                />
                                {/* {errors.img_alt_tag && touched.img_alt_tag && (
                                    <div className="text-red-600 text-sm">{errors.img_alt_tag}</div>
                                )} */}

                            </div>
                        </div>

                        <div className="sm:col-span-2 w-full">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Image banner<span className="text-[12px] text-blue-500 ml-2">(Note:Please upload an image with 1920*654)</span>
                            </label>
                            <div className=" ">
                                {
                                    singleImage ?
                                        <div className="flex items-center justify-center w-full h-60 rounded-lg px-1 border-2 border-dashed border-gray-400 bg-gray-200">
                                            <div className="relative ">
                                                <div className="border border-black w-full h-[200px] rounded-lg overflow-hidden relative  flex justify-center items-center">
                                                    <img src={`${singleImage?.includes("https")?singleImage:IMAGEURL+singleImage}`} className=" top-0 left-0 w-fit h-full  opacity-30" alt="name" />

                                                    <div className="absolute top-0  w-full   flex justify-center items-center h-full">
                                                        <button
                                                            className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                            // onClick={() => setSingleImage(null)}
                                                            onClick={() => {
                                                                setSingleImage(null);
                                                                setSingleImageProgress(0);
                                                                setFieldValue("h_banner_image1", "");
                                                            }}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="flex  w-full flex-col">
                                            {
                                                parseInt(singleImageProgress) > 0 && singleImageProgress < 100 ?
                                                    <div className="w-full h-60 flex justify-center items-center">
                                                        <Spinner />
                                                    </div>
                                                    :
                                                    <label className="w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                        <span className="text-md text-gray-500">* Upload Image</span>
                                                        <input type="file" className="hidden" accept="image/*"
                                                            // onChange={(e) => handleFileChange(e, setSingleImage)}
                                                            onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                handleFileChange(e, setSingleImage);
                                                                setFieldValue("h_banner_image1", file?.name || "");
                                                                setFieldTouched("h_banner_image1", true, false);


                                                                if (!file) {
                                                                    setFieldError("h_banner_image1", "Image is required");
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                if (!values.h_banner_image1) {
                                                                    setFieldError("h_banner_image1", "Image is required");
                                                                }
                                                            }}
                                                        />


                                                    </label>
                                            }
                                            {errors.h_banner_image1 && touched.h_banner_image1 && (
                                                <div className="text-red-600 text-sm">{errors.h_banner_image1}</div>
                                            )}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5 justify-end ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button

                        type="submit"
                        
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddBanner