import React, { useEffect, useState } from "react";
import { useSearchParams, useParams,useNavigate } from "react-router-dom";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { Switch } from '@headlessui/react';
import MainLoader, { RotatingLineSpinner } from "../../../components/Spinner";
import Spinner from "../../../components/Spinner";


const EditRolePermission = () => {

    const { role_id } = useParams();
    const [rolePermissionData, setRolePermissionData] = useState([])
    const [roleName, setRoleName] = useState("");
    const [permission, setPermission] = useState([]);
    const [submitLoader,setSubmitLoader] =useState(false)
    const [enabled, setEnabled] = useState(false);
    const [loader, setLoader] = useState(true);

     const navigate = useNavigate();

    //console.log("roleId", role_id)



    const getAllRolePermission = async (id) => {
        try {
            //console.log("getAllRolePermission",id)
            const response = await MasterServices.getRolePermissionDataApi(id);
            //console.log("getRolePermissionDataApi", response)
            if (response?.data?.data?.length > 0) {
                setRolePermissionData(response?.data?.data);
                setPermission(response?.data?.data)
                setRoleName(response?.data?.data[0]?.user_role_name);
                setLoader(false)
            } else {
                setRolePermissionData({})
                setLoader(false)
            }
        }
        catch (error) {
            setLoader(false)
            toast.error(error?.data?.message)
        }
    }

    useEffect(() => {
        if (role_id) {
            getAllRolePermission(role_id)
        }
    }, [role_id]);

    const handleToggle = (index, field) => {
        const updatedPermissions = [...permission];
        // console.log("updatedPermissions",updatedPermissions)
        //updatedPermissions[index][field] = !updatedPermissions[index][field];
        updatedPermissions[index][field] = updatedPermissions[index][field] === 'N' ? 'Y' : 'N';
        console.log("updatedPermissions", index, field)
        console.log("updatedPermissions", updatedPermissions[index][field])
        setPermission(updatedPermissions);
    };


    console.log("permission", permission)

    const handleSubmit = async()=>{
        if(permission?.length>0){
            //console.log("handleSubmit",permission);
            try {
                const payload ={
                    permission_data:permission
                }
                console.log("payload",payload)
                const response = await MasterServices.addUserRoleMapperApi(payload);
                console.log("handleSubmit",response)
                if(response?.data?.success===true){
                    toast.success("Added Permission Successfully ");
                    navigate("/roles")

                }

            } catch (error) {
                toast.error(error)
            }
            
        }
    }

    const clearForm = ()=>{
        navigate("/roles")
    }
    return (
        <>
        {
            loader?
            <Spinner />
            :
            <div>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Edit Permission</h1>
            </div>

            <h4 className="text-md font-semibold text-gray-900 mt-5">Role : {roleName ? roleName : ""}</h4>
            <form>
            <div className="grid grid-cols-3 gap-5 my-5">
                {
                    permission?.map((ele, index) => {
                        return (
                            <div className="flex flex-col bg-white rounded-lg  gap-y-5">
                                <div className="border-b-[1px] border-[#ebac78] p-3">
                                    <label className="text-md capitalize  text-[#3a4b43] mt-5 ">{ele?.module_name}</label>
                                </div>
                                <div className="flex flex-col gap-y-5 mb-5 px-5">
                                    <div className="flex justify-between">
                                        <h6>Add</h6>
                                        <Switch
                                            checked={ele.is_add === "Y"}
                                            onChange={() => handleToggle(index, 'is_add')}
                                            className={`${ele.is_add === 'Y' ? 'bg-teal-900' : 'bg-gray-400'}
          relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${ele.is_add === 'Y' ? 'translate-x-5' : 'translate-x-0'}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                    </div>
                                    <div className="flex justify-between">
                                        <h6>Edit</h6>
                                        <Switch
                                            checked={ele?.is_edit === "Y"}
                                            onChange={() => handleToggle(index, 'is_edit')}
                                            className={`${ele?.is_edit === "Y" ? 'bg-teal-900' : 'bg-gray-400'}
          relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${ele?.is_edit === "Y" ? 'translate-x-5' : 'translate-x-0'}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                    </div>
                                    <div className="flex justify-between">
                                        <h6>View</h6>
                                        <Switch
                                            checked={ele?.is_view === "Y"}
                                            onChange={() => handleToggle(index, 'is_view')}
                                            className={`${ele?.is_view === "Y" ? 'bg-teal-900' : 'bg-gray-400'}
          relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                aria-hidden="true"
                                                className={`${ele?.is_view === "Y" ? 'translate-x-5' : 'translate-x-0'}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                    </div>


                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="flex gap-5 justify-end mt-5 ">
                <button
                    onClick={clearForm}
                    type="button"
                    className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                >
                    Cancel
                </button>
                <button

                    type="button"
                    onClick={() => handleSubmit()}
                    className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                >
                    {submitLoader ?
                        <RotatingLineSpinner /> : "Submit"
                    }
                </button>
            </div>
            </form>
            </div>
}
        </>
    )
}

export default EditRolePermission