import * as Yup from "yup";

export const productSchema = Yup.object({
  p_product_name: Yup.string().required("Please Enter Field"),
  p_product_short_desc: Yup.string().required("Please Enter Field"),
  p_product_price: Yup.number().required("Please Enter Field"),
  p_original_purchase_price: Yup.number().required("Please Enter Field"),
});

export const userSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  newPassword: Yup.string().min(
    6,
    "New Password must be at least 6 characters"
  ),
  roleType: Yup.string().nullable().required("Role is required"),
});

export const orderSchema = Yup.object({
  b_billing_date: Yup.string().required("Date is required"),

  b_firstName: Yup.string().required("First Name is required"),

  b_lastName: Yup.string().required("Last Name is required"),

  b_addressOne: Yup.string().required("Address 1 is required"),

  b_zipCode: Yup.string().required("ZipCode is required"),

  b_city: Yup.string().required("City is required"),

  b_email: Yup.string().required("Email is required"),

  b_phone: Yup.string().required("Phone is required"),

  paymentMethod: Yup.string().nullable().required("Payment Method is required"),

  selectCustomer: Yup.string().nullable().required("Customer is required"),

  orderStatus: Yup.string().nullable().required("Status is required"),

  b_country: Yup.string().nullable().required("Country is required"),

  s_country: Yup.string().nullable().required("Country is required"),

  s_product: Yup.array()
    .min(1, "At least one product must be selected") // Validate at least one product is selected
    .required("Product selection is required"),

  s_firstName:Yup.string().required("First Name is required"),
  s_lastName: Yup.string().required("Last Name is required"),
  s_addressOne: Yup.string().required("Address 1 is required"),
  s_zipCode: Yup.string().required("ZipCode is required"),
  s_city: Yup.string().required("City is required"),
  s_phone: Yup.string().required("Phone is required"),
});


export const bannerSchema = Yup.object({
  h_banner_type:Yup.string().required("type is required"),
  h_banner_order: Yup.string().required("order is required"),
  h_banner_redirect_link: Yup.string().required("redirect link is required"),
  h_banner_active: Yup.string().required("Status is required"),
  h_banner_image1: Yup.string().required("Image is required"),
  // img_alt_tag: Yup.string().required("tag is required")
})

export const imageSectionSchema = Yup.object({
  h_image_section_heading:Yup.string().required("heading is required"),
  h_section_order: Yup.string().required("order is required"),
  h_banner_redirect_link: Yup.string().required("redirect link is required"),
  h_image_section_active: Yup.string().required("Status is required"),
  h_iamge_url: Yup.string().required("Image is required"),
  
})

export const designerSectionSchema = Yup.object({
  h_designers_section_heading:Yup.string().required("heading is required"),
  h_designers_section_order: Yup.string().required("order is required"),
  h_designers_redirect_link: Yup.string().required("redirect link is required"),
  h_designers_in_section_active: Yup.string().required("Status is required"),
  h_iamge_url: Yup.string().required("Image is required"),
  
})

export const reditSectionSchema = Yup.object({
  h_re_edit_section_heading:Yup.string().required("heading is required"),
  h_re_edit_section_order: Yup.string().required("order is required"),
  h_re_edit_section_redirect_link: Yup.string().required("redirect link is required"),
  h_re_edit_section_active: Yup.string().required("Status is required"),
  h_iamge_url: Yup.string().required("Image is required"),
  
})


export const celebritySectionSchema = Yup.object({
  h_celebrity_section_heading:Yup.string().required("heading is required"),
  h_celebrity_section_order: Yup.string().required("order is required"),
  h_celebrity_section_redirect_link: Yup.string().required("redirect link is required"),
  h_celebrity_in_section_active: Yup.string().required("Status is required"),
  h_iamge_url: Yup.string().required("Image is required"),
  img_alt_tag:Yup.string().required("tag is required"),
  
})

export const storySectionSchema = Yup.object({
  h_stories_section_heading:Yup.string().required("heading is required"),
  h_stories_section_order: Yup.string().required("order is required"),
  h_stories_section_redirect_link: Yup.string().required("redirect link is required"),
  h_stories_section_active: Yup.string().required("Status is required"),
  h_iamge_url: Yup.string().required("Image is required"),
  
  
})