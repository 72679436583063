import { Fragment, useEffect, useState,useContext,useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../assests/images/revivifylogo.png";
import logoarrow from "../assests/images/revivifyarrow.png";
import FallBackProfile from "../assests/images/profile.png";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  InboxStackIcon,
  QuestionMarkCircleIcon,
  BookOpenIcon,
  Cog6ToothIcon,
  ShieldCheckIcon,
  ClipboardDocumentCheckIcon,
  ChevronDownIcon,
  ShoppingCartIcon,
  LightBulbIcon,
  TicketIcon,
  WalletIcon,
  ListBulletIcon,PhotoIcon
} from "@heroicons/react/24/outline";
import MasterServices from "../ApiServices/MasterServices";
import MainLoader from "../components/Spinner";
import { CustomContext } from "../context/MyContext";
// import {
//   ChevronDownIcon,
//   MagnifyingGlassIcon,
// } from "@heroicons/react/20/solid";

const navigation = [
  { name: "Dashboard", to: "/", icon: HomeIcon, current: false },
  { name: "Users", to: "/user-page", icon: UsersIcon, current: false },

  {
    name: "Products",
    to: "/product-page",
    icon: InboxStackIcon,
    current: false,
  },
  {
    name: "Orders",
    to: "/order-page",
    icon: ClipboardDocumentCheckIcon,
    current: false,
  },
  {
    name: "Category",
    to: "/category-page",
    icon: QuestionMarkCircleIcon,
    current: false,
  },
  {
    name: "Attributes",
    to: "#",
    icon: BookOpenIcon,
    current: false,
    submenu: [
      {
        name: "Parent Attribute",
        to: "/parentattribute",
        current: false,
        icon: ShieldCheckIcon,
      },
      {
        name: "Child Attribute",
        to: "/childAttribute",
        current: false,
        icon: ShieldCheckIcon,
      },
      {
        name: "Values",
        to: "/attributevalues",
        current: false,
        icon: ShieldCheckIcon,
      },
    ],
  },
  { name: "Brands", to: "/brand-page", icon: Cog6ToothIcon, current: false },
  // { name: "Tags", to: "/tag-page", icon: TagIcon, current: false },
  // { name: "SEO", to: "/seo", icon: LightBulbIcon, current: false },
  {
    name: "Abandoned",
    to: "/abandoned",
    icon: ShoppingCartIcon,
    current: false,
  },
  { name: "Voucher", to: "/voucher", icon: TicketIcon, current: false },
  { name: "Wallet", to: "/wallet", icon: WalletIcon, current: false },
];
const teams = [
  { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
  { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
  { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Example=({ children })=> {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [menuActive, setMenuActive] = useState("/");
  const [scrolled, setScrolled] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [loader,setLoader] = useState(true)
  const { getRolePermissionData } = useContext(CustomContext)
  const [submenuOpen, setsubmenuOpen] = useState({
    appointment: false,
    forms: false,
    master: false,
    settings: false,
  });

  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userRoleID = userDetails?.u_user_role_id;

  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const handleMenuClick = (item) => {
    setMenuActive(item);
  };
  const iconMapping = {
    "HomeIcon": HomeIcon,
    "UsersIcon": UsersIcon,
    "InboxStackIcon": InboxStackIcon,
    "ClipboardDocumentCheckIcon": ClipboardDocumentCheckIcon,
    "QuestionMarkCircleIcon": QuestionMarkCircleIcon,
    "ShieldCheckIcon": ShieldCheckIcon,
    "Cog6ToothIcon": Cog6ToothIcon,
    "ShoppingCartIcon": ShoppingCartIcon,
    "TicketIcon": TicketIcon,
    "WalletIcon": WalletIcon,
    "BookOpenIcon":BookOpenIcon,
    "ShieldCheckIcon":ShieldCheckIcon,
    "ListBulletIcon":ListBulletIcon,
    "PhotoIcon":PhotoIcon
  };
  

  useEffect(() => {
    const fetchdata = async () => {
      getMenuData();
    };

    fetchdata();
  }, []);

  const handleLogout = () => {
    setTimeout(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("rolePermissionData");
      window.location.href = "/login";
    }, 1000);
  };

  const userNavigation = [
    { name: "Your profile", href: "#", onclick: "", click: false },
    { name: "Sign out", href: "#", onclick: handleLogout, click: true },
  ];
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(()=>{
    if(userRoleID){
      getRolePermissionData(userRoleID)
    }
  },[userRoleID])

  const organizeModules = (data) => {
    const map = new Map();
    const result = [];

    data.forEach((module) => {
      if (module.module_type === "Menu") {
        map.set(module.module_id, { ...module, submenu: [] });
        result.push(map.get(module.module_id));
      } else if (module.module_type === "SubMenu" && module.module_type_id) {
        const parent = map.get(module.module_type_id);
        if (parent) {
          parent.submenu.push(module);
        }
      }
    });

    return result;
  };

  const getMenuData = async () => {
    try {
      const response = await MasterServices.getModuleDataApi();
     // console.log("getMenuData", response);
      if (response?.data?.data?.length > 0) {
        const organizedData = organizeModules(response?.data?.data);
        setLoader(false)
       
        setMenuData(organizedData);
      } else {
        setLoader(false)
        setMenuData([]);
      }
    } catch (error) {
      setLoader(false)
      console.log("getMenuData", error);
    }
  };

  useEffect(()=>{
    console.log("menuData",menuData)
    if(menuData?.length<0){
      console.log("menuData",menuData)
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("rolePermissionData");
      window.location.href = "/login";
    }
  },[menuData])


  const handleTimerLogout = () => {
    alert('You have been logged out due to inactivity.');
    
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("rolePermissionData");
    window.location.href = "/login";
    
};


const resetIdleTimer = () => {
    if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
    }
    // Set the timeout to 5 minutes (300000 ms)
    timeoutRef.current = setTimeout(handleTimerLogout, 900000);
};

   useEffect(() => {
    // Add event listeners for user activity
    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];
    events.forEach((event) => window.addEventListener(event, resetIdleTimer));

    // Set the initial timeout
    resetIdleTimer();

    
    return () => {
        events.forEach((event) => window.removeEventListener(event, resetIdleTimer));
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };
}, []);

  return (
    <div className="h-full">
    {
      loader?
      <MainLoader />
      :
      <div>
        <Transition show={sidebarOpen}>
          <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <TransitionChild
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </TransitionChild>

            <div className="fixed inset-0 flex">
              <TransitionChild
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <TransitionChild
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </TransitionChild>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#ffffff] px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center gap-2">
                      <img
                        className="h-5 w-auto"
                        src={logoarrow}
                        alt="Your Company"
                      />
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {menuData.map((item) => {
                              const IconComponent = iconMapping[item.module_icon] || LightBulbIcon; 
                              return(
                              <li key={item.module_id}>
                                <Link
                                  to={item.module_path}
                                  onClick={() => {
                                    handleMenuClick(item.module_path);
                                    setsubmenuOpen({
                                      ...submenuOpen,
                                      [item.module_name.toLowerCase()]:
                                        !submenuOpen[
                                          item.module_name.toLowerCase()
                                        ],
                                    });
                                    if (item?.module_name !== "Attributes") {
                                      setSidebarOpen(false);
                                    }
                                  }}
                                  className={classNames(
                                    window.location.pathname ===
                                      item.module_path
                                      ? "bg-[#3a4b43] text-white"
                                      : "text-black hover:translate-x-6 ease-in-out duration-700",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <IconComponent
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.module_name}
                                  {item.module_type === "SubMenu" && (
                                    <ChevronDownIcon
                                      className={` ${
                                        submenuOpen && "-rotate-0"
                                      } mr-3 h-6 w-6 flex-shrink-0 -rotate-90 text-black duration-150`}
                                      aria-hidden="true"
                                    />
                                  )}
                                </Link>
                                {item.submenu &&
                                  submenuOpen[
                                    item.module_name.toLowerCase()
                                  ] && (
                                    <div>
                                      {submenuOpen &&
                                        item.submenu.map(
                                          (submenuItem, index) => {
                                            const IconComponent = iconMapping[submenuItem.module_icon] || LightBulbIcon; 
                                            return(
                                            <Link
                                              to={submenuItem?.module_path}
                                              onClick={() => {
                                                setSidebarOpen(false);
                                              }}
                                              className={classNames(
                                                window.location.pathname ===
                                                  submenuItem?.module_path
                                                  ? "bg-[#3a4b43] text-white"
                                                  : "text-black ",
                                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                              )}
                                              key={submenuItem?.module_name}
                                            >
                                              <IconComponent
                                                className={`${
                                                  window.location.pathname ==
                                                  submenuItem?.module_path
                                                    ? " text-white"
                                                    : "text-[#3a4b43]"
                                                } ml-5 mr-3 h-5 w-6 flex-shrink-0`}
                                              />
                                              {submenuItem.module_name}
                                            </Link>
                                          )
                                      })}
                                    </div>
                                  )}
                              </li>
                            )})}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col  ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#ffffff] px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center gap-2">
              <img className="h-6 w-auto object-cover " src={logoarrow} />
              <img
                className="h-8 w-auto object-cover"
                src={logo}
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {menuData.map((item) => {
                       const IconComponent = iconMapping[item.module_icon] || LightBulbIcon; 
                      return(
                      <li key={item.module_id}>
                        <Link
                          to={item.module_path}
                          onClick={() => {
                            handleMenuClick(item?.module_path);
                            setsubmenuOpen({
                              ...submenuOpen,
                              [item?.module_name.toLowerCase()]:
                                !submenuOpen[item?.module_name.toLowerCase()],
                            });
                          }}
                          className={classNames(
                            window.location.pathname === item.module_path
                              ? "bg-[#3a4b43] text-white"
                              : "text-black  ",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <IconComponent
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.module_name}
                          {item.module_path === "#" && (
                            <ChevronDownIcon
                              className={` ${
                                submenuOpen && "-rotate-0"
                              } mr-3 h-6 w-6 flex-shrink-0 -rotate-90 text-black duration-150`}
                              aria-hidden="true"
                            />
                          )}
                        </Link>
                        {item?.submenu &&
                          submenuOpen[item?.module_name.toLowerCase()] && (
                            <div>
                              {submenuOpen &&
                                item.submenu.map((submenuItem, index) =>{
                                  const IconComponent = iconMapping[submenuItem.module_icon] || LightBulbIcon; 
                                  return(
                                  <Link
                                    to={submenuItem.module_path}
                                    className={classNames(
                                      window.location.pathname ===
                                        submenuItem.module_path
                                        ? "bg-[#3a4b43] text-white"
                                        : "text-black ",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                    key={submenuItem.module_name}
                                  >
                                    <IconComponent
                                      className={`${
                                        window.location.pathname ==
                                        submenuItem.module_path
                                          ? " text-white"
                                          : "text-[#3a4b43]"
                                      } ml-5 mr-3 h-5 w-6 flex-shrink-0`}
                                      aria-hidden="true"
                                    />
                                    {submenuItem.module_name}
                                  </Link>
                                )})}
                            </div>
                          )}
                      </li>)
                    })}
                  </ul>
                </li>
                {/* <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                    href="#"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    Settings
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div
            className={`sticky top-0 z-30 flex h-16 shrink-0 items-center gap-x-4  bg-[#f8f4ee] px-4 sm:gap-x-6 sm:px-6 lg:px-8 ${
              scrolled ? "glass-effect" : ""
            }`}
          >
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 justify-between gap-x-4 self-stretch lg:gap-x-6 ">
              <div className="flex justify-center w-full items-center">
                <img className="h-8 w-auto " src={logo} alt="Your Company" />
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6 ">
                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative w-full">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      src={FallBackProfile}
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900 whitespace-nowrap"
                        aria-hidden="true"
                      >
                        {`${userDetails?.u_user_first_name} ${userDetails?.u_user_last_name}`}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </MenuButton>
                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <MenuItem key={item.name}>
                          {({ focus }) => (
                            <Link
                              to={item.click ? item.href : null}
                              onClick={item.click ? item.onclick : null}
                              className={classNames(
                                focus ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </MenuItem>
                      ))}
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">{children}</div>
          </main>
        </div>
      </div>
}
    </div>
  );
}

export default Example;