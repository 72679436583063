import Api from "./Api";
import { toast } from "react-toast";

export default {


  // Role Api
  async getModuleDataApi(){
    try {
      const response = await Api().get(`/api/role/getAllModule`);

      return response;
    } catch (error) {
      console.log("error in getModuleDataApi",error)
      return error
    }
  },

  async getAllRolesApi(){
    try {
      const response = await Api().get(`/api/role/getAllUserRrole`);

      return response;
    } catch (error) {
      console.log("error in getAllRolesApi",error)
      return error
    }
  },
  async addRolesApi(payload){
    try {
      const response = await Api().post("/api/role/add-user-role", payload);

      return response;
    } catch (error) {
      console.log("error in addRolesApi",error)
      return error
    }
  },
  async editRolesApi(payload){
    try {
      const response = await Api().put("/api/role/edit-user-role", payload);

      return response;
    } catch (error) {
      console.log("error in editRolesApi",error)
      return error
    }
  },

  async getRolePermissionDataApi(id){
    try {
      const response = await Api().get(`/api/role/getRolePermission/${id}`);

      return response;
    } catch (error) {
      console.log("error in getRolePermissionDataApi",error)
      return error
    }
  },

  async addUserRoleMapperApi(payload){
    try {
      const response = await Api().post("/api/role/add-user-role-mapper", payload);

      return response;
    } catch (error) {
      console.log("error in addUserRoleMapperApi",error)
      return error
    }
  },

  
  //currency

  async getCurrency(){
    try {
      const response = await Api().get(`/api/homepage/get-currency`);

      return response;
    } catch (error) {
      console.log("error in getCurrency",error)
      return error
    }
  },

  async getCurrencyConversionData(){
    try {
      const response = await Api().get(`/api/homepage/get-currency-data`);

      return response;
    } catch (error) {
      console.log("error in getCurrencyConversionData",error)
      return error
    }
  },

 

  // USER
  async getAllUsers() {
    try {
      const response = await Api().post("/api/admin/get-users");

      return response;
    } catch (error) {
      console.log("error in getAllUsers",error)
      return error
    }
  },

  async getUserById(id) {
    try {
      const response = await Api().get(`/api/admin/get-users/${id}`);

      return response;
    } catch (error) {
      console.log("error in getUserById",error)
      return error
    }
  },

  async addUsers(payload){
    try {
      const response = await Api().post("/api/register-user", payload);

      return response;
    } catch (error) {
      console.log("error in addUsers",error)
      return error
    }
  },

  async editUser(payload){
    try {
      const response = await Api().post("/api/update-profile-dashboard", payload);

      return response;
    } catch (error) {
      console.log("error in addUsers",error)
      return error
    }
  },

  // Dashboard
  async getAllDashboard(data) {
    try {
      var url = "/api/admin/get-order-reports";
      if (data.startDate && data.endDate) {
        url += "?startDate=" + data.startDate + "&endDate=" + data.endDate;
      }
      const response = await Api().get(url);
      return response;
    } catch (error) {
      console.log("error in getAllDashboard",error)
      return error
    }
  },

  // Dashboard Line Graph API
  async getAllDashboardLineGarph(data) {
    try {
      var url = "/api/admin/get-daywise-order-reports";
      if (data.startDate && data.endDate) {
        url += "?startDate=" + data.startDate + "&endDate=" + data.endDate;
      }
      const response = await Api().get(url);
      return response;
    } catch (error) {
      console.log("error in getAllDashboardLineGarph",error)
      return error
    }
  },

  // PRODUCT
  async addProducts(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/products", body);

      return response;
    } catch (error) {
      console.log("error in addProducts",error)
      return error
    }
  },
  async editProducts(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/products", body);

      return response;
    } catch (error) {
      console.log("error in editProducts",error)
      return error
    }
  },

  async importProductApi(body) {
    
    try {
      const response = await Api().post("/api/fileupload/uploadProductsFile", body);

      return response;
    } catch (error) {
      console.log("error in importProductApi",error)
      return error
    }
  },

  async getProductImage(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/fileupload/uploadFile", body);

      return response;
    } catch (error) {
      console.log("error in getProductImage",error)
      return error
    }
  },
  async getAllProduct() {
    try {
      const response = await Api().get("/api/admin/products");

      return response;
    } catch (error) {
      console.log("error in getAllProduct",error)
      return error
    }
  },
  async getAllPageMeta() {
    try {
      const response = await Api().get("/api/admin/seo");

      return response;
    } catch (error) {
      console.log("error in getAllPageMeta",error)
      return error
    }
  },
  async getDesginerProductCount(id) {
    try {
      const response = await Api().get(
        `/api/admin/get-designer-product-count/${id}`
      );

      return response;
    } catch (error) {
      console.log("error in getDesginerProductCount",error)
      return error
    }
  },

  async getProductById(id) {
    try {
      const response = await Api().get(`/api/admin/products/?product_id=${id}`);

      return response;
    } catch (error) {
      console.log("error in getProductById",error)
      return error
    }
  },

  // ORDERS
  async getAllOrder() {
    try {
      const response = await Api().get("/api/admin/get-orders");

      return response;
    } catch (error) {
      console.log("error in getAllOrder",error)
      return error
    }
  },

  async addOrders(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/create-order", body);

      return response;
    } catch (error) {
      console.log("error in addOrders",error)
      return error
    }
  },
  async editOrders(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/orders", body);

      return response;
    } catch (error) {
      console.log("error in editOrders",error)
      return error
    }
  },
  async getOrderById(id) {
    try {
      const response = await Api().get(`/api/admin/get-orders/?order_id=${id}`);

      return response;
    } catch (error) {
      console.log("error in getOrderById",error)
      return error
    }
  },

  async getOrderStatus() {
    try {
      const response = await Api().get("/api/admin/get-order-status");

      return response;
    } catch (error) {
      console.log("error in getOrderStatus",error)
      return error
    }
  },

  async getAddressById(id) {
    try {
      const response = await Api().get(`https://api-prod.revivify.com/api/admin/address?user_id=${id}`);

      return response;
    } catch (error) {
      console.log("error in getAddressById",error)
      return error
    }
  },




  // CATEGORY

  async addCategory(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/product-category", body);

      return response;
    } catch (error) {
      console.log("error in addCategory",error)
      return error
    }
  },
  async editCategory(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/product-category", body);

      return response;
    } catch (error) {
      console.log("error in editCategory",error)
      return error
    }
  },
  async getAllCategory() {
    try {
      const response = await Api().get("/api/admin/product-category");

      return response;
    } catch (error) {
      console.log("error in getAllCategory",error)
      return error
    }
  },
  async getSingleCategory(id) {
    try {
      const response = await Api().get(`/api/admin/product-category/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleCategory",error)
      return error
    }
  },
  async getSingleSubCategory(id) {
    try {
      const response = await Api().get(`/api/admin/product-sub-category/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleSubCategory",error)
      return error
    }
  },
  async getAllCatAndSubCat() {
    try {
      const response = await Api().get("/api/admin/product-category-sub");

      return response;
    } catch (error) {
      console.log("error in getAllSubCategory",error)
      return error
    }
  },

  // ATTRIBUTE

  async getAllAttributes() {
    try {
      const response = await Api().get("/api/admin/product-attributes");
      return response;
    } catch (error) {
      console.log("error in getAllAttributes",error)
      return error
    }
  },

  async getAttributeValueById(id) {
    try {
      const response = await Api().get(`/api/admin/attribute-values?p_attribute_value_id=${id}`,);
      
      return response;
    } catch (error) {
      console.log("error in getSingleAttributeValue",error)
      return error
    }
  },

  async addCustomValue(payload) {
    try {
      const response = await Api().post("/api/admin/attribute-values", payload);

      return response;
    } catch (error) {
      console.log("error in addCustomValue",error)
      return error
    }
  },

  async editCustomValue(payload) {
    try {
      const response = await Api().put("/api/admin/attribute-values", payload);

      return response;
    } catch (error) {
      console.log("error in editCustomValue",error)
      return error
    }
  },

  async getAllCustomValue() {
    try {
      const response = await Api().get("/api/admin/attribute-values");

      return response;
    } catch (error) {
      console.log("error in getCustomValue",error)
      return error
    }
  },

  async getAllAttributesColorValue(id) {
    try {
      const response = await Api().get(
        `api/admin/attribute-values/?p_product_attribute_id=${id}`
      );
      return response;
    } catch (error) {
      console.log("error in getAllAttributesColorValue",error)
      return error
    }
  },

  async addProductAttribute(data) {
    try {
      const response = await Api().post(`/api/admin/product-attributes`, data);
      return response;
    } catch (error) {
      console.log("error in addProductAttribute",error)
      return error
    }
  },

  async editProductAttribute(data) {
    try {
      const response = await Api().put(`/api/admin/product-attributes`, data);
      return response;
    } catch (error) {
      console.log("error in editProductAttribute",error)
      return error
    }
  },

  async addAttributeChild(data) {
    try {
      const response = await Api().post(`/api/admin/attribute-child`, data);
      return response;
    } catch (error) {
      console.log("error in addAttributeChild",error)
      return error
    }
  },

  async editAttributeChild(data) {
    try {
      const response = await Api().put(`/api/admin/attribute-child`, data);
      return response;
    } catch (error) {
      console.log("error in editAttributeChild",error)
      return error
    }
  },

  // async addAttributesValue(data) {
  //   try {
  //     const response = await Api().put(`/api/admin/attribute-child`, data);
  //     return response;
  //   } catch (error) {
  //     console.log("error in editAttributeChild",error)
  //     return error
  //   }
  // },

  async getAttributes() {
    try {
      const response = await Api().get(`/api/admin/product-attributes`);
      return response;
    } catch (error) {
      console.log("error in getAttributes",error)
      return error
    }
  },

  async getParentAttributeById(id) {
    try {
      const response = await Api().get(
        `api/admin/product-attributes/${id}`
      );
      return response;
    } catch (error) {
      console.log("error in getParentAttributeById",error)
      return error
    }
  },

  async getChildAttriValueByid(id){
    try {
      const response = await Api().get(`api/admin/attribute-child/${id}`);
      return response;
    } catch (error) {
      console.log("error in getChildAttriValue",error)
      return error
    }
  },

  async getAttributeChild() {
    try {
      const response = await Api().get(`/api/admin/attribute-child`);
      return response;
    } catch (error) {
      console.log("error in getAttributeChild",error)
      return error
    }
  },

  async getChildAttriValue(id){
    try {
      const response = await Api().get(`api/admin/attribute-values/?p_child_attribute_id=${id}`);
      return response;
    } catch (error) {
      console.log("error in getChildAttriValue",error)
      return error
    }
  }
,

  // BRANDS
  async getAllBrands() {
    try {
      const response = await Api().get("/api/admin/designers");
      return response;
    } catch (error) {
      console.log("error in getAllBrands",error)
      return error
    }
  },

  async addBrand(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/designers", body);

      return response;
    } catch (error) {
      console.log("error in addBrand",error)
      return error
    }
  },

  async editBrand(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/designers", body);

      return response;
    } catch (error) {
      console.log("error in editBrand",error)
      return error
    }
  },

  async getSingleBrand(id) {
    try {
      const response = await Api().get(`/api/admin/designers/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleBrand",error)
      return error
    }
  },
  async getSingleSubBrand(id) {
    try {
      const response = await Api().get(`/api/admin/sub-designers/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleSubBrand",error)
      return error
    }
  },

  async checkSkuValue(value){
    try {
      const response = await Api().get(`/api/product/sku-check/${value}`);

      return response;
    } catch (error) {
      console.log("error in checkSkuValue",error)
      return error
    }
  },

  // Abandoned Cart
  async getAbandonedList() {
    try {
      const response = await Api().get(`/api/admin/get-abandant-cart`);

      return response;
    } catch (error) {
      console.log("error in getAbandonedList",error)
      return error
    }
  },

  async sendEmailAbondantEmail(payload) {
    try {
      const response = await Api().post("/api/admin/send-abandant-cart-mail", payload);
      console.log("sendEmailAbondantEmail")
      return response;
    } catch (error) {
      console.log("error in sendEmailAbondantEmail",error)
      return error
    }
  },



  // Voucher
  async addVoucher(payload) {
    try {
      const response = await Api().post(`/api/voucher/add`,payload);

      return response;
    } catch (error) {
      console.log("error in addVoucher",error)
      return error
    }
  },

  async editVoucher(payload) {
    try {
      const response = await Api().put(`/api/voucher/edit`,payload);

      return response;
    } catch (error) {
      console.log("error in editVoucher",error)
      return error
    }
  },



  async getAllVoucher() {
    try {
      const response = await Api().get(`/api/voucher/get`);

      return response;
    } catch (error) {
      console.log("error in getAbandonedList",error)
      return error
    }
  },
  async getSingleVoucher(id) {
    try {
      const response = await Api().get(`/api/voucher/getSingle/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleVoucher",error)
      return error
    }
  },

  async applyCoupon(payload) {
    try {
      const response = await Api().post(`api/voucher/apply`,payload);

      return response;
    } catch (error) {
      console.log("error in addVoucher",error)
      return error
    }
  },

  // Wallet
  async addWallet(payload) {
    try {
      const response = await Api().post(`api/admin/add-wallet-balace/`,payload);

      return response;
    } catch (error) {
      console.log("error in addWallet",error)
      return error
    }
  },
  async getAllWallet() {
    try {
      const response = await Api().get(`/api/admin/get-wallet-logs`);

      return response;
    } catch (error) {
      console.log("error in getAllWallet",error)
      return error
    }
  },


  // Upload Images
  async getAllBanner() {
    try {
      const response = await Api().get(`/api/homepage/get-banners`);

      return response;
    } catch (error) {
      console.log("error in getAllBanner",error)
      return error
    }
  },

  async addBanner(payload){
    try {
      const response = await Api().post(`api/homepage/add-banner/`,payload);

      return response;
    } catch (error) {
      console.log("error in addBanner",error)
      return error
    }
  },
  async editBanner(payload){
    try {
      const response = await Api().put(`api/homepage/edit-banner/`,payload);

      return response;
    } catch (error) {
      console.log("error in editBanner",error)
      return error
    }
  },

  async getSingleBannerApi(id) {
    try {
      const response = await Api().get(`/api/homepage/get-single-banner/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleBannerApi",error)
      return error
    }
  },

  // imageSection

  async getAllHomePageDataApi() {
    try {
      const response = await Api().get(`/api/homepage/get-homepage-data`);

      return response;
    } catch (error) {
      console.log("error in getAllHomePageDataApi",error)
      return error
    }
  },

  async addImageSectionApi(payload){
    try {
      const response = await Api().post(`api/homepage/add-image-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in addImageSectionApi",error)
      return error
    }
  },
  async editImageSectionApi(payload){
    try {
      const response = await Api().put(`api/homepage/edit-image-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in editImageSectionApi",error)
      return error
    }
  },

  async getSingleImageSectionApi(id) {
    try {
      const response = await Api().get(`/api/homepage/get-single-imageSection/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleImageSectionApi",error)
      return error
    }
  },

  // designer Section
  async addDesignerSectionApi(payload){
    try {
      const response = await Api().post(`api/homepage/add-designer-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in addDesignerSectionApi",error)
      return error
    }
  },

  async editDesignerSectionApi(payload){
    try {
      const response = await Api().put(`api/homepage/edit-designer-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in editDesignerSectionApi",error)
      return error
    }
  },

  async getSingleDesignerSectionApi(id) {
    try {
      const response = await Api().get(`/api/homepage/get-single-designerSection/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleDesignerSectionApi",error)
      return error
    }
  },


  // redit section

  async getSingleReditSectionApi(id) {
    try {
      const response = await Api().get(`/api/homepage/get-single-reditSection/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleReditSectionApi",error)
      return error
    }
  },

  async addReditSectionApi(payload){
    try {
      const response = await Api().post(`api/homepage/add-redit-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in addReditSectionApi",error)
      return error
    }
  },

  async editReditSectionApi(payload){
    try {
      const response = await Api().put(`api/homepage/edit-redit-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in editReditSectionApi",error)
      return error
    }
  },


  // celebrity section
  async getSingleCelebritySectionApi(id) {
    try {
      const response = await Api().get(`/api/homepage/get-single-celebritySection/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleCelebritySectionApi",error)
      return error
    }
  },

  async addCelebritySectionApi(payload){
    try {
      const response = await Api().post(`api/homepage/add-celebrity-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in addCelebritySectionApi",error)
      return error
    }
  },

  async editCelebritySectionApi(payload){
    try {
      const response = await Api().put(`api/homepage/edit-celebrity-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in editCelebritySectionApi",error)
      return error
    }
  },

  // story section
  async getSingleStorySectionApi(id) {
    try {
      const response = await Api().get(`/api/homepage/get-single-storySection/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleStorySectionApi",error)
      return error
    }
  },
  async addStorySectionApi(payload){
    try {
      const response = await Api().post(`api/homepage/add-story-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in addStorySectionApi",error)
      return error
    }
  },

  async editStorySectionApi(payload){
    try {
      const response = await Api().put(`api/homepage/edit-story-section/`,payload);

      return response;
    } catch (error) {
      console.log("error in editStorySectionApi",error)
      return error
    }
  },


};
